<template>
    <div style="height: 2.5rem; border-radius: 10px; z-index: 5;" id="container">
        <div class="tools">
            <el-tooltip class="item" effect="dark" content="锚点绘制" placement="left">
                <i class="iconfont icon-maodianbiaohui" :class="{ 'active': activeIcon === 'anchor' }"
                    @click="activateIcon('anchor')"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="矩形绘制" placement="left">
                <i class="iconfont icon-juxingbiaohui" :class="{ 'active': activeIcon === 'rectangle' }"
                    @click="activateIcon('rectangle')"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="left">
                <i class="iconfont icon-shanchubiaohui" @click="deletes"></i>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import api from '../api.js'
export default {
    components: {},
    data() {
        return {
            activeIcon: '', // 当前激活的图标
            map: null,
            mouseTool: null,
            mapArr: [],
            mapList: [],
            Cancelarr:[],
            isShow: true,
            newMap:[]
        };
    },
    watch: {
    },

    methods: {
        initAMap() {
            const self = this; // 存储当前的this
            AMapLoader.load({
                key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
                // version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                version: "2.0",
                plugins: [
                    "AMap.MouseTool",
                ],
                AMapUI: {
                    // 是否加载 AMapUI，缺省不加载
                    version: "1.1", // AMapUI 缺省 1.1
                    plugins: [], // 需要加载的 AMapUI ui插件
                },
            })
                .then((AMap) => {
                    self.map = new AMap.Map("container", {
                        // 设置地图容器id
                        viewMode: "2D", // 是否为3D地图模式
                        zoom: 15, // 初始化地图级别
                        terrain: true, //开启地形图
                        center: [117.303216,31.803165], // 初始化地图中心点位置
                    });
                    self.mouseTool = new AMap.MouseTool(self.map)
                    self.mouseTool.on('draw', (event) => {
                        let arr = event.obj.getPath().map(item => {
                            return {
                                longitude: item.lng,
                                latitude: item.lat
                            }
                        })
                        let arr1 = {
                            colour: '#a5d2ed',
                            points: arr
                        }
                        self.mapArr.push(arr1)
                    })

                })
                .catch((e) => {
                    console.log(e);
                });
        },
        activateIcon(e) {
            this.activeIcon = e;
            if (!this.mouseTool) {
                this.mouseTool = new AMap.MouseTool(this.map)
            }
            if (e === 'anchor') {
                // 锚点绘制
                this.mouseTool.close(false);
                this.mouseTool.polygon({
                    strokeColor: "#556bff",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillColor: 'rgba(87, 176, 231, 0.31)',
                    fillOpacity: 0.5,
                    strokeStyle: "solid",
                })
            } else if (e === 'rectangle') {
                // 矩形绘制
                this.mouseTool.close(false);
                this.mouseTool.rectangle({
                    strokeColor: '#556bff',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: 'rgba(87, 176, 231, 0.31)',
                    fillOpacity: 0.5,
                    strokeStyle: 'solid',
                })
            }

        },
        // 删除
        deletes() {
            if (this.mouseTool) {
                this.mouseTool.close(true);
                this.activeIcon = '';
                this.mapArr = []
                this.mapList = []
                this.map.clearMap()
            }
        },
        getMaparr() {
            return this.mapArr
        },
        retMaplist() {
            return this.mapList
        },
        // 查询
        async getgrid(id) {
            try {
                const res = await api.getAtlas(id)
                // console.log(res);
                if (res.data === "未标绘") return
                let arr = []
                res.data.forEach(item => {
                    arr.push(item.points)
                })
                this.mapList = arr.map(item => {
                    return item.map(it => {
                        return [it.longitude, it.latitude];
                    });
                });
                this.newMap = this.mapList
                setTimeout(() => {
                    this.getBox(this.mapList)
                }, 500);
            } catch (error) {
                console.error(error); // 处理错误
            }


        },
        // 编辑渲染图层
        getBox(arr) {
            arr.forEach(pathArray => {
                var polygon = new AMap.Polygon({
                    path: pathArray
                });
                this.map.add(polygon);
            });
        },
        // 卸载地图
        unloadMap() {
            if (this.map) {
                this.map.clearMap();
                // console.log('销毁地图');
                // this.map.destroy();
            }


        },
        
        processdata() {
         let arr = this.newMap.map((points) => {
          return {
            colour: "#a5d2ed",
            points: points.map((point) => {
              return { longitude: point[0], latitude: point[1] };
            }),
          };
        });
        this.mapArr = arr
        //  console.log(arr);
        },
        // Cancelarr(){
        //   return this.Cancelarr
        // }

    },

    created() {

    },

    mounted() {
        this.initAMap()

    },
    beforeDestroy() {
        this.unloadMap();
    },

}
</script>
<style lang='less' scoped>
#container {
    position: relative;

    .tools {
        position: absolute;
        right: 3%;
        bottom: 5%;
        z-index: 99990;
        padding-top: 1%;
        padding-bottom: 1%;
        background-color: #ffffff;
        height: 25%;
        width: 5%;
        border-radius: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        i {
            font-size: 0.12rem;
        }

    }
}

.active {
    color: #556bff !important;
}
</style>
